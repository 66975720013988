import React from "react";

export function Content({ children, centered = false, type = "" }) {
  return (
    <main
      className={`content ${centered ? "content--centered" : ""} ${
        type ? `content--${type}` : ""
      }`}
    >
      {children}
    </main>
  );
}
